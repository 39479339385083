import {
  facilityAttributeIsRequired,
  FacilityFieldsArray,
  FacilityFieldType,
  GetFacilitySchemaQuery,
  nameof,
  Section,
  SectionHeaderHeight,
  useGetFacilitySchemaQuery,
  __TypeKind
} from '@ndustrial/contxt-common/src';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ValueGetterParams
} from 'ag-grid-community';
import { attributeSearchAtom } from './Atoms';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledSection = styled(Section)`
  height: 100%;
`;

const GridWrapper = styled.div`
  height: calc(100% - ${SectionHeaderHeight}px);
  width: 100%;

  .ag-root-wrapper {
    border: none;
    border-radius: 5px;
  }
`;

const defaultColDef = {
  sortable: true,
  filter: 'agTextColumnFilter',
  resizable: true
};

// this function is differentiating between complex/simple fields.
// Simple fields should be of scalar type and not require additional parameters/fields to query
function fieldIsSimple(field: FacilityFieldType) {
  if (
    field.type.kind === __TypeKind.SCALAR ||
    field.type?.ofType?.kind === __TypeKind.SCALAR ||
    field.type.kind === __TypeKind.ENUM ||
    field.type?.ofType?.kind === __TypeKind.ENUM
  ) {
    return true;
  } else {
    return false;
  }
}

const StyledErrorDiv = styled.div`
  padding: 10px;
`;

export interface AttributeListProps {
  className?: string;
}

function getAttributeType(params: ValueGetterParams) {
  const data = params.data as FacilityFieldType;
  return data.type.name || data.type.ofType?.name;
}

function getAttributeIsRequired(params: ValueGetterParams) {
  return facilityAttributeIsRequired(params.data as FacilityFieldType)
    ? 'True'
    : 'False';
}

export default function AttributeList(props: AttributeListProps) {
  const [simpleFields, setSimpleFields] = useState<FacilityFieldsArray>();
  const [gridApi, setGridApi] = useState<GridApi>();
  const history = useHistory();
  const searchValue = useRecoilValue(attributeSearchAtom);

  const allFacilityFieldsQuery = useGetFacilitySchemaQuery({
    onCompleted: (data: GetFacilitySchemaQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tempSimpleFields: any[] = [];
      data.__type?.fields?.forEach((field) => {
        if (fieldIsSimple(field)) {
          tempSimpleFields.push(field);
        }
      });
      setSimpleFields(tempSimpleFields);
    }
  });

  function onGridReady(event: GridReadyEvent) {
    event.api.sizeColumnsToFit();
    setGridApi(event.api);
  }
  useEffect(() => {
    allFacilityFieldsQuery.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  useEffect(() => {
    gridApi?.setQuickFilter(searchValue);
  }, [gridApi, searchValue]);

  if (allFacilityFieldsQuery.error)
    return (
      <StyledErrorDiv className={props.className}>
        {allFacilityFieldsQuery.error.message}
      </StyledErrorDiv>
    );

  return (
    <Wrapper>
      <StyledSection title="Attributes">
        <GridWrapper className="ag-theme-alpine">
          <AgGridReact
            className={props.className}
            rowData={simpleFields}
            animateRows={true}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            columnDefs={colDef}
            // comment this back in when we're ready to edit attributes:
            // onCellClicked={(event: CellClickedEvent) =>
            //   history.push(
            //     history.location.pathname + `/attribute/${event.data.name}`
            //   )
            // }
          />
        </GridWrapper>
      </StyledSection>
    </Wrapper>
  );
}

const colDef: ColDef[] = [
  { field: nameof<FacilityFieldType>('name'), sort: 'asc' },
  { field: nameof<FacilityFieldType>('description') },
  { headerName: 'Type', valueGetter: getAttributeType },
  { headerName: 'Is Required', valueGetter: getAttributeIsRequired }
];
